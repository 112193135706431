@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Noto Sans CJK TC - Regular";
    font-style: normal;
  }
  h1 {
    @apply text-3xl block my-[0.67em] mx-0 font-bold;
  }
  h2 {
    @apply text-2xl block my-[0.83em] mx-0 font-bold;
  }
  /* 只有 h3, h4 有根據 figma 樣式做修改 */
  h3 {
    @apply text-3xl block my-4 mx-0 font-normal;
  }
  h4 {
    @apply text-xl block my-[1.33em] mx-0 font-normal;
  }
  h5 {
    @apply text-base block my-[1.67em] mx-0 font-bold;
  }
  h6 {
    @apply text-sm block my-[2.33em] mx-0 font-bold;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ul,
  ol {
    list-style: revert;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
